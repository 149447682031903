<template>
  <modal-draggable :is-open="isOpen" side="left" class="!p-0 flex flex-col" :class="{ 'h-full': isMobile }">
    <modal-header v-if="isMobile" is-bw :icon="isUser ? 'UserProfile' : 'Menu'" title="Menu" @close="emit('close')" />

    <div
      class="grow flex flex-col space-y-8 py-6 overflow-auto relative bg-no-repeat bg-[top_right]"
      :class="isMobile ? 'w-full' : 'w-72'"
      :style="isUser && !isActive ? `background-image: url('${filmUrl}'); background-size: 100%; ` : null"
    >
      <div v-if="isUser" class="px-4 space-y-4 text-center">
        <div class="text-center overflow-hidden text-ellipsis">
          <a href="https://www.dehancer.com/profile/products" class="link text-[18px] font-medium">{{ email }}</a>
        </div>

        <div>
          <manage-subscription-link />
        </div>

        <div v-if="!isActive">
          <div class="text-alert text-2xl font-bold mt-14">
            Subscribe now
          </div>

          <div class="text-[#FFFAF1] text-xl font-medium mt-8 mb-14">
            <div>Get Unlimited Exports</div>
            <div>in High Resolution</div>
            <div>…and more!</div>
          </div>

          <router-link :to="{ name: 'pricesPage', query: { i: $route.query.i } }" class="btn-sm mt-1 mb-4 h-8 w-full max-w-60 font-medium">
            <template v-if="didUseTrial">Purchase subscription</template>
            <template v-else>Start {{ trialDurationDays }} days trial</template>
          </router-link>
        </div>
      </div>

      <template v-else>
        <div class="text-center">
          <img src="../assets/icons/icnAuthorize.png" class="size-16 mx-auto mt-10 mb-8 select-none" alt="Sign In / Sign Up">
          <button type="button" class="btn-sm w-36 mb-12" @click="showLogin">Sign&nbsp;In / Sign&nbsp;Up</button>
        </div>
      </template>

      <last-image />

      <template v-if="isMobile && isStandalone">
        <a class="link block mx-auto w-auto mt-2" @click.prevent="painfullyReloadWindow">Check for updates</a>
      </template>

      <div>
        <div class="text-center text-pale text-sm font-light leading-6">
          <div>© Dehancer Team, 2014–2025</div>
        </div>

        <div class="text-center text-sm font-normal leading-6 mt-3">
          <div>
            <a href="https://support.dehancer.com/" target="_blank" class="link mx-auto w-32">Support</a>
            <span class="border-r border-gray-600 mx-2" />
            <router-link :to="{ name: 'pricesPage', query: { i: $route.query.i } }" class="link mx-auto w-32">Pricing</router-link>
            <span class="border-r border-gray-600 mx-2" />
            <a href="https://www.dehancer.com/legal/dehancer-online-terms-conditions" target="_blank" class="link mx-auto w-32">Terms</a>
          </div>

          <div>
            <span class="link block mx-auto w-32" @click.prevent="showWhatsNew">What's new?</span>
          </div>
          <div>
            <a href="https://www.dehancer.com" target="_blank" class="link block mx-auto w-32">dehancer.com</a>
          </div>

          <!--
          <a href="https://www.dehancer.com" target="_blank" class="link mr-2">dehancer.com</a>
          <a href="https://www.dehancer.com" class="link" @click.prevent="stripeTest">.</a>
          -->

          <a v-if="isUser" href="/logout" class="link block mx-auto w-32 mt-2">Sign&nbsp;out</a>
        </div>
      </div>
    </div>
  </modal-draggable>
</template>

<script setup lang="ts">
import ModalDraggable from './ModalDraggable.vue';
import ModalHeader from './ModalHeader.vue';
import ManageSubscriptionLink from './ManageSubscriptionLink.vue';
import LastImage from './LastImage.vue';
import { isMobile, isStandalone } from '../breakpoints';
import { isUser, email, isActive, didUseTrial } from '../user';
import { trialDurationDays } from '../priceTiers';
import { showLogin } from '../auth';
import filmUrl from '../assets/filmBlue.webp';
import { fetch as fetchWhatsNew } from '../whatsnew';

// this is the only way to check for updates for now
function painfullyReloadWindow() {
  window.location.reload();
}

defineProps<{
  isOpen: boolean
}>();

const emit = defineEmits<{
  close: [void]
}>();

async function showWhatsNew() {
  await fetchWhatsNew(true);
  emit('close');
}
</script>
